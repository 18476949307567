import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'formatAmount'
})
export class FormatAmountPipe implements PipeTransform {

    transform(valueInCents: number): string {
        const valueInDollar = (valueInCents / 100);
        return ((valueInDollar < 0) ? '-' : '') + '$' + Math.abs(valueInDollar).toFixed(2);
    }

}
