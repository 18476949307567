import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient, private userService: UserService,private apiService: ApiService) { }



  login(username: string, password: string): Promise<any> {
    return this.apiService.post(`${environment.API}/login`, { username: username, password: password });
  }

  async otpVerification(username: string, otp: string): Promise<void> {
    try {
      const req = {
        username: username,
        OTP: otp
      }
      const response = await this.userService.otpVerification(req);
     
    } catch (e) {
      console.error('error', e)
    }
  }

  async logout() {
    await localStorage.clear();
    // await this.userService.updateUserDetails.next();
  }


  forgotPassword(request: any) {
    try {
      return this.http.post(`${environment.API}/forgotpassword`, request).pipe(map((data: any) => {
        if (data.data) {
          data = data.data;
        }
        return data;
      }));
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
  verifyForgotPasswordToken(request: any) {
    try {
      return this.http.get(`${environment.API}/verifyForgotPasswordToken/${request}`).pipe(map((data: any) => {
        return true;
      }));
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}
