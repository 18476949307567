import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { Login } from 'src/app/models/login.model';
import { Utils } from 'src/app/others/utils/utils';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { MyNavService } from 'src/app/shared/services/NavService/my-nav.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  model: Login = new Login('admin@gmail.com', 'admin@!23');
  submitted = false;
  loginForm!: FormGroup;
  returnUrl!: string;
  showError: boolean = false;
  showEmailError: boolean = false;
  userId: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private navService: MyNavService,
    private utils: Utils,
    private userService: UserService,
    private fromBuilder: FormBuilder,
    private utilService: UtilService) {

  }

  ngOnInit(): void {
    this.loginForm = this.fromBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    if (this.userService.getUserDetails()) {
      this.router.navigate(['/dashboard']);
      return;
    }
    // this.authService.logout();
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  get f() { return this.loginForm.controls; }

  async onSubmit(): Promise<void> {
    this.showError = false;
    this.showEmailError = false;
    if (this.loginForm.invalid) {
      this.showError = true;
      return;
    }
    if (!this.utils.isValidEmail(this.f.username.value)) {
      this.showEmailError = true;
      return;
    }

    try {
      const userLoginDetails:any ={
        username:this.f.username.value,
        password:this.f.password.value
      }
      localStorage.setItem('userLoginDetails', JSON.stringify(userLoginDetails));
      const userDetails = await this.authService.login(this.f.username.value, this.f.password.value);
     
      if (userDetails.data && !userDetails.data.isAccess) {
        this.utils.showConfirmationDialog(userDetails.data.message, '', async () => {
        },'OK', '');
      } else {
        // localStorage.clear();
        this.navService.myParam = { username: this.f.username.value };
        this.router.navigate(['/otp-verification']);
      }

    } catch (error) {
      this.utilService.notifyError(error);
      console.error(error);
    }
    
  }


}
