import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/others/utils/utils';
import { SubscriptionPlan } from 'src/app/shared/models';
import { UserService } from 'src/app/shared/services/user/user.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { SwmsReportService } from '../swms-report/swms-report.service';
import { PayementService } from './payment.service';
import { environment } from 'src/environments/environment';
import { runInThisContext } from 'vm';

declare var Stripe: any;
declare var $: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  @Input() planList: any = [];
  @Input() activeSubscription: any = [];
  @Input() subscriptionId: any = '';
  @Input() showPayment: any;

  @Output('subscription')
  subscriptionEvent: EventEmitter<any> = new EventEmitter<any>();
  paymentButtonEnabled: boolean = false;
  private stripe: any;
  private card: any;
  paymentIntent: any;
  cardError: string;
  selectPlan: number = 0;
  selectedSubscriptionPlanList: SubscriptionPlan[];
  activePriceId: string;
  user: any;
  paymentMethodId: string;
  url: any;
  units?: number;
  newPriceId: any;
  selectMember: number = 0;
  totalAmount: number = 0;
  name: string;
  isUserSubscription: any;
  userId: any;
  tenantDetails: any;
  upgradPlanDetails;
  workerSubscriptionId: any;
  workerCode: any;
  workerTrialDetails: any;
  workerPriceId: any;
  constructor(
    private utils: Utils,
    private utilService: UtilService,
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private paymentService: PayementService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.url = this.router.url.split('/')[1];
    const getselectMember = this.activatedRoute.snapshot.queryParams.member;
    this.name = this.activatedRoute.snapshot.queryParams.name;
    this.userId = this.activatedRoute.snapshot.params.id;
    this.workerSubscriptionId =
      this.activatedRoute.snapshot.queryParams.workerSubscriptionId;
    this.workerCode = this.activatedRoute.snapshot.queryParams.workerCode;

    this.selectMember = Number(getselectMember);
    if (this.url == 'register-tenant') {
      this.showPayment = true;
    }
    if (this.url.includes('member-subscription')) {
      localStorage.removeItem('planDetails');
      this.selectMember = Number(getselectMember);
    }
    if (this.url.includes('otp-verification')) {
      this.isUserSubscription =
        this.activatedRoute.snapshot.queryParams.isUserSubscription;
    }
  }

  ngOnInit(): void {
    this.init();
  }
  async init() {
    await this.getPlanList();
    await this.showPlanDetailsForUpgrad();

    this.cardError = undefined;
    this.setupStripePayment();
    $('#payment_modal').modal('show');
    this.user = this.userService.getUserDetails();
    this.tenantDetails = this.userService.getCurrentTenant();
  }
  showPlanDetailsForUpgrad() {
    if (this.url.includes('profile')) {
      this.url = 'profile-new';
      const subcriptionId =
        this.activatedRoute.snapshot.queryParams.subscriptionId;
      this.subscriptionId = Number(subcriptionId);
      const data = this.planList[0];
      this.selectPlan = Number(data.price);
      let planDetails;
      planDetails = data;
      localStorage.setItem('planDetails', JSON.stringify(planDetails));
      this.totalAmount = Number(data.price);
    }
  }
  showModal(paymentDetails?: any): void {
    if (paymentDetails) {
      this.selectPlan = paymentDetails.selectPlan;
      this.units = paymentDetails.numberOfAssignment;
    } else {
      this.getPlanList();
    }
    this.showPayment = true;
    this.cardError = undefined;
    this.setupStripePayment();
    $('#payment_modal').modal('show');
    this.user = this.userService.getUserDetails();
  }

  private async setupStripePayment(): Promise<void> {
    setTimeout(() => {
      this.stripe = Stripe(environment.publicKey);
      const elements = this.stripe.elements();
      const style = {
        base: {
          color: '#32325d',
          fontFamily: 'Arial, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          borderWidth: '1px',
          borderStyle: 'solid',
          '::placeholder': {
            color: '#32325d',
          },
        },
        invalid: {
          fontFamily: 'Arial, sans-serif',
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      };

      const card = elements.create('card', { style, hidePostalCode: true });
      // Stripe injects an iframe into the DOM
      card.mount('app-payment #card-element');
      card.on('change', (event) => {
        // Disable the Pay button if there are no card details in the Element
        this.paymentButtonEnabled = !event.empty;
        this.cardError = event.error ? event.error.message : '';
      });

      this.card = card;
    }, 100);
  }

  async payWithCard() {
    let planDetails;
    planDetails = localStorage.getItem('planDetails');
    if (!planDetails) {
      this.utilService.notifyError('Please select price');
    } else {
      this.utils.showConfirmationDialog(
        'Are you sure want to submit?',
        '',
        async () => {
          if (!this.cardError) {
            $('#payment_modal').modal('hide');
            this.spinner.show();
            this.paymentMethodId = await this.createPaymentMethod();
            if (this.paymentMethodId) {
              try {
                if (this.activeSubscription.status === 'active') {
                  await this.paymentService.changeSubscription(
                    this.paymentMethodId,
                    this.newPriceId,
                    this.subscriptionId
                  );
                } else {
                  const convertPlanDetails = JSON.parse(planDetails);
                  convertPlanDetails.name = convertPlanDetails.name;

                  await this.createSubscription(
                    convertPlanDetails.name,
                    this.paymentMethodId,
                    convertPlanDetails.priceId
                  );
                }
              } catch (error) {
                this.utilService.notifyError('Payment failed please try again');
              }
            }
          }
          this.spinner.hide();
        },
        'Yes',
        'No'
      );
    }
  }

  private createPaymentMethod() {
    if (this.subscriptionId) {
      const planDetails: any = this.planList.filter((item: any) => {
        return item.price == this.selectPlan;
      })[0];
      this.newPriceId = planDetails.priceStripeId;
      planDetails.name = this.user.firstname + '' + this.user.lastname;
      localStorage.setItem('planDetails', JSON.stringify(planDetails));
    }
    const planDetails: any = localStorage.getItem('planDetails');
    if (!planDetails) {
      this.utilService.notifyError('Please select price');
      return;
    }
    const convertPlanDetails = JSON.parse(planDetails);
    return this.stripe
      .createPaymentMethod({
        type: 'card',
        card: this.card,
        billing_details: {
          name: convertPlanDetails.name,
        },
      })
      .then((result: any) => {
        if (result.error) {
          this.utilService.notifyError('Card details is not valid');
          // throw result.error;
        } else {
          return result.paymentMethod.id;
        }
      });
  }

  async askUserForAuthentication(
    subscriptionId: string,
    clientSecret: string
  ): Promise<any> {
    return this.stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: this.card,
        },
      })
      .then((result: any) => {
        if (result.error) {
          throw result.error;
        } else {
          this.handleSuccess(subscriptionId);
        }
      });
  }

  private async createSubscription(
    name: string,
    paymentMethodId: string,
    priceId: string
  ): Promise<void> {
    if (this.url.includes('profile')) {
      await this.paymentService.changeSubscription(
        paymentMethodId,
        this.planList[0].priceStripeId,
        this.subscriptionId
      );
      this.utilService.notifySuccess('Subscription has been upgraded.');
      this.subscriptionEvent.emit(paymentMethodId);
    }
    if (this.selectMember || this.workerSubscriptionId) {
      const existTenant = this.userService.getSelectRole();
      let tenantId = 0;
      if (existTenant) {
        tenantId = existTenant.id;
      } else {
        tenantId = this.user.id;
      }
      let userDetails = this.userService.getCurrentTenant();
      const request = {
        price: 100 * Number(this.selectMember),
        tenantId: userDetails.id,
        paymentType: 'trial',
        units: this.selectMember,
        priceId: priceId?priceId:this.workerPriceId,
      };
      if (priceId == '5') {
        const response = await this.paymentService.unitPaymentIntent(request);
        this.paymentIntent = response.data.paymentIntentClientSecret;
        this.stripe
          .confirmCardPayment(this.paymentIntent, {
            payment_method: {
              card: this.card,
            },
          })
          .then(async (result: any) => {
            if (result.error) {
              this.utilService.notifyError(result.error.message);
            } else {
              const subscriptionDetails = {
                subscriptionId: response.data.subscriptionId,
                isFree: true,
                priceId: priceId?priceId:this.workerPriceId,
              };

              this.subscriptionEvent.emit(subscriptionDetails);
              this.utilService.notifySuccess(
                'Member subscription has been added.'
              );
              this.router.navigate(['/member-subscription']);
            }
          })
          .then(async () => {
            await this.spinner.hide();
          })
          .catch(async (error: any) => {
            await this.spinner.hide();
          });
      } else {
        if (!priceId) {
          this.utilService.notifyError('please select price');
        }
        const subscriptionDetails = {
          tenantId: tenantId,
          name: name,
          paymentMethodId: paymentMethodId,
          priceId: priceId?priceId:this.workerPriceId,
          units: this.selectMember ? this.selectMember : 1,
          workerCode: this.workerCode ? this.workerCode : '',
        };
        const subscriptionList = await this.paymentService.createSubscription(
          subscriptionDetails
        );
        const subscriptionWorkerDetails = {
          subscriptionId: subscriptionList.data.subcriptionDetails.insertId,
          isFree: false,
          workerId: this.workerSubscriptionId,
          workerCode: this.workerCode,
          priceId:priceId?priceId:this.workerPriceId
        };
        this.subscriptionEvent.emit(subscriptionWorkerDetails);
        this.utilService.notifySuccess('Member subscription has been added.');
        this.router.navigate(['/member-subscription']);
      }
    }

    if (this.url == 'register-tenant' || this.isUserSubscription) {
      const userId = this.userId ? this.userId : this.tenantDetails.id;
      const subscription =
        await this.paymentService.createNewTenantSubscription(
          name,
          paymentMethodId,
          priceId,
          userId
        );
      await this.handleSubscription(subscription.data.subcriptionDetails);
    }
  }

  private async handleSubscription(subscription?: any): Promise<void> {
    if (subscription.status === 'active') {
      this.handleSuccess(subscription.subscriptionId);
    } else {
      await this.askUserForAuthentication(
        subscription.subscriptionId,
        subscription.clientSecret
      );
    }
  }

  private handleSuccess(subscriptionId?: string): void {
    // this.utilService.notifySuccess('Subscribed successfully');
    this.subscriptionEvent.emit(subscriptionId);
    this.router.navigate(['/login']);
  }

  async getPlanList(): Promise<void> {
    if (this.url.includes('profile')) {
      try {
        const req = {
          isUpdatedPlan: true,
        };
        const response = await this.paymentService.getPlanList(req);
        let planList = response.data;
        if (this.subscriptionId) {
          this.planList = planList.filter((item) => {
            return item.interval == 'year' && item.productName == 'tenant';
          });
        } else {
          this.planList = planList;
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      try {
        const response = await this.paymentService.getPlanList();
        let planList = response.data;
        this.planList = planList;
      } catch (err) {
        console.error(err);
      }
    }
  }

  async getSelectPlan(selectPlan: any) {
    this.workerPriceId = selectPlan.priceId
    this.selectPlan = selectPlan.price;
    let planDetails;
    let userDetails;
    planDetails = selectPlan;
    if (this.isUserSubscription) {
      userDetails = this.userService.getCurrentTenant();
      planDetails.name = userDetails.firstname + '' + userDetails.lastname;
      this.totalAmount = this.selectPlan;
    } else if (this.selectMember) {
      this.totalAmount = selectPlan.price * this.selectMember;
      planDetails.name = this.user.firstname + '' + this.user.lastname;
    } else if (this.workerSubscriptionId) {
      this.selectMember = 1;
      this.totalAmount = selectPlan.price * this.selectMember;
      planDetails.name = this.user.firstname + '' + this.user.lastname;
    } else {
      this.totalAmount = this.selectPlan;
      planDetails.name = this.name;
    }
    localStorage.setItem('planDetails', JSON.stringify(planDetails));
  }
}
