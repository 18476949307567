import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginModule} from './pages/login/login.module';
import {GeneralModule} from './components/common/general.module';
import {AuthGuard} from './others/guards/auth/auth.guard';
import {LoginGuard} from './others/guards/auth/login.guard';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {JwtInterceptor} from './others/helpers/jwt/jwt.interceptor';
import {ErrorInterceptor} from './others/helpers/error/error.interceptor';
import {ReactiveFormsModule} from '@angular/forms';
import {OtpValidationComponent} from './pages/otp-validation/otp-validation.component';
import {Utils} from './others/utils/utils';
import {LogoutComponent} from './pages/logout/logout.component';
import {ForgotPasswordModule} from './pages/forgot-password/forgot-password.module';
import {PathLocationStrategy, LocationStrategy} from '@angular/common';
import { LoaderComponent } from './others/loader/loader.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AlertDialogModule } from './shared/alert-dialog/alert-dialog.module';
import { AlertService } from './shared/services/alert/alert.service';
import { AuthenticationService } from './shared/services/authentication/authentication.service';
import { MyNavService } from './shared/services/NavService/my-nav.service';
import { UserService } from './shared/services/user/user.service';







@NgModule({
  declarations: [
    AppComponent,
    LogoutComponent,
    LoaderComponent,
    // DocumentComponent,
    // FormatAmountPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    AlertDialogModule,
    LoginModule,
    GeneralModule,
    ForgotPasswordModule,
  ],
  providers: [
    AuthGuard,
    LoginGuard,
    AlertService,
    AuthenticationService,
    MyNavService,
    UserService,
    Utils,
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: LocationStrategy, useClass: PathLocationStrategy}
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  // exports:[FormatAmountPipe]
})
export class AppModule {
}
