import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoginRoutes } from './login-router';
import { AuthGuard } from 'src/app/others/guards/auth/auth.guard';
import { LoginGuard } from 'src/app/others/guards/auth/login.guard';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(LoginRoutes)
  ],
  providers: [
    AuthGuard,
    LoginGuard,
    AuthenticationService,
   
   
  ],
})
export class LoginModule { }
