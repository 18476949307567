import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import { UserService } from 'src/app/shared/services/user/user.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private userService: UserService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const currentUser = this.userService.getUserDetails();
    const currentTenant = this.userService.getCurrentTenant();

    if (currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`,
        }
      });
      const myBody: any = request.body;
      let userDetails: any = {};
      if (currentUser) {
        userDetails = {
          ...userDetails,
          // userId: currentUser.id,
          type: currentUser.type
        };
      }

      if (currentTenant) {
        userDetails = {
          ...userDetails,
          // tenantId: currentTenant.id
        };
      }

      request = request.clone({
        body: {
          ...myBody,
          ...userDetails
        }
      });
    }
    return next.handle(request);
  }
}
