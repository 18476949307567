import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PaymentRoutes } from './payment-router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaymentComponent } from './payment.component';
import { FormatAmountPipe } from 'src/app/shared/format-amount.pipe';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [PaymentComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    // RouterModule.forChild(PaymentRoutes)
  ],
  exports:[PaymentComponent]
})
export class PaymentModule { }
