import { Injectable } from '@angular/core';
import * as toastr from 'toastr';
import {Observable, Subject, timer} from 'rxjs';
import {map, takeWhile, tap} from 'rxjs/operators';

declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class UtilService {
    paginationRecord = [10,20,30];

    constructor() {
    }
    userRole =[
        {
            roleName:"Master Admin",
            type:1
        },
        {
            roleName:"Tenant Admin",
            type:2
        },
        {
            roleName:"Supervisor",
            type:3
        },
        {
            roleName:"Worker",
            type:4
        }
      
    ]


    public static isNumber(value: number): boolean {
        return $.isNumeric(value);
    }

    public static toNumber(value: number): number {
        return UtilService.isNumber(value) ? Number(value) : 0;
    }
    getPaginationRecord() {
        return JSON.parse(JSON.stringify(this.paginationRecord))
    }
    public notifySuccess(message: string) {
        this.notify(message, 'success', 5000);
    }

    public notifySuccessApp(message: string) {
        this.notify(message, 'success', 10000);
    }

    public notifyError(message: string) {
        this.notify(message, 'error', 5000);
    }

    public notify(message: string, type = 'info', duration: number = 5000) {
        toastr.options = {
            newestOnTop: false,
            positionClass: 'toast-top-right',
            preventDuplicates: false,
            showDuration: '500',
            hideDuration: '500',
            showEasing: 'swing',
            hideEasing: 'linear',
            showMethod: 'fadeIn',
            hideMethod: 'fadeOut'
        };

        if (duration) {
            toastr.options.timeOut = duration;
        } else {
            toastr.options.timeOut = false;
            toastr.options.positionClass = toastr.options.positionClass + ' manual-close';
        }
        toastr[type](message);
    }

    countdown(startTimeInSecond: number): Observable<number> {
        return timer(1000, 1000)
            .pipe(
                takeWhile(() => startTimeInSecond > 0),
                tap(() => startTimeInSecond--),
                map(() => startTimeInSecond)
            );

    }

}
