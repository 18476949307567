import {Injectable} from '@angular/core';
import { Subject } from 'rxjs';
import Swal, {SweetAlertResult} from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class Utils {

  confirmationDialogSubject: Subject<{ title: string, description: string, confirmationCallback: () => void, confirmButtonText:string, cancelButtonText:string }> = new Subject<{ title: string, description: string, confirmationCallback: () => void, confirmButtonText: string, cancelButtonText: string }>();

  isValidMobile(str: any): boolean {
    if (!str || str.length === 0) {
      return true;
    }
    if (str && (str.length < 10 || str.length > 15)) {
      return false;
    }
    return !isNaN(Number(str));
  }

  isValidEmail(email: string): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  showSuccessAlert(message: string): Promise<SweetAlertResult<any>> {
    return Swal.fire({
      title: message,
      timer: 2000,
      icon: 'success',
      showCloseButton: true,
      showConfirmButton: false,
    });
  }

  public showConfirmationDialog(title: string, description: string, confirmationCallback: () => void, confirmButtonText: string = 'Confirm', cancelButtonText: string = 'Cancel') {
    this.confirmationDialogSubject.next({title, description, confirmationCallback, confirmButtonText, cancelButtonText});
}

  showErrorAlert(message?: string): Promise<SweetAlertResult<any>> {
    return Swal.fire({
      title: (message) ? message : 'Something went wrong',
      timer: 5000,
      icon: 'error',
      showCloseButton: true,
      showConfirmButton: false
    });
  }

  showNotification(message: string): Promise<SweetAlertResult<any>> {
    return Swal.fire({
      title: (message) ? message : 'Something went wrong',
      timer: 5000,
      icon: 'warning',
      showCloseButton: true,
      showConfirmButton: false
    });
  }
}
