import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/shared/services/user/user.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { Tenant } from 'src/app/models/tenant.model';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class TenantService {

  user: any;
  registerTenantTrigger: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient, private userService: UserService, private apiService: ApiService) {
    this.user = this.userService.getUserDetails();
  }

  registerTenant(request: Tenant): Promise<any> {
    let id = 0;
    if(this.user){
      this.user.id = this.user.id
    }else{
      id = id;
    }
    return this.apiService.post(`${environment.API}/tenants/inviteTenant`, { ...request, userId: id });
  }

  getTenant(request: any): Promise<any> {
    return this.apiService.post(`${environment.API}/tenants/getTenants`, request);
  }

  public getTenantById(request: any): Promise<any> {
    return this.apiService.get<any>(`/tenants/getTenants/${request.id}`);
  }

  changeStatusOfTenant(request:any): Promise<any> {
    return this.apiService.post(`${environment.API}/updateStatusOfTenant`, request);
  }

  reSendMail(id:any): Promise<any> {
    return this.apiService.post(`${environment.API}/tenants/resendMail`, {id});
  }


  // getTenantById(request: any): Observable<any> {
  //   return this.http.post(`${environment.API}/tenants/${request.id}`, {}).pipe(map((data: any) => {
  //     return data.data[0];
  //   }));
  // }

  getDetails(request: any): Promise<any> {
    return this.apiService.get(`${environment.API}/register/tenant/${request.userId}`);
  }

  updateDetails(request: Tenant): Promise<any> {
    return this.apiService.post(`${environment.API}/register/tenant`, request);
  }

  workerRegister(request: Tenant): Promise<any> {
    return this.apiService.post(`${environment.API}/workerRegister`, request);
  }


  updateTenantDetails(request: Tenant): Promise<any> {
    return this.apiService.put(`${environment.API}/tenants/${request.id}`, request)
  }

  uploadFile(request: any): Observable<any> {
    return this.http.post(`${environment.API}/uploadFile`, request).pipe(map((data: any) => {
      return data;
    }));
  }
}
