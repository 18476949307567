import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/shared/services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private myRouter: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const currentRole = this.userService.getSelectRole();
    if (currentRole && currentRole.role_id == 1) {
      return true;
    } else {
      this.myRouter.navigate(['/dashboard'], {queryParams: {returnUrl: state.url}});
      return false;
    }
  }
}
