import {Component,ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { Utils } from './others/utils/utils';
import { ConfigurePriceService } from './pages/configure-price/configure-price.service';
import { ConfirmationDialogComponent } from './shared/alert-dialog/confirmation-dialog/confirmation-dialog.component';
import { UserService } from './shared/services/user/user.service';
import { UtilService } from './shared/services/util.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title: string = 'HazardEye-Web';
  isLogin: boolean = true;
  user: any;
  displayLoading: boolean = false;
  isSignatureForm:boolean =false;
  @ViewChild('confirmationDialogComponent', { static: true }) confirmationDialogComponent: ConfirmationDialogComponent;
  constructor(private router: Router, private userService: UserService, private utils: Utils,private utilService: UtilService,
    private activatedRoute: ActivatedRoute,
    private configurePriceService:ConfigurePriceService) {
      console.log(' this.activatedRoute', this.activatedRoute)
    this.checkRouting();
    // this.checkRoutingForSignature();
    // this.checkRoutingForWorkerSignature();

    //handle multiple tabs//
    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        let token = localStorage.getItem('currentUser');
        if(token == undefined) { 
            // this.utilService.notifyError('Session expired.');
            this.router.navigate(['/login']); 
        }
      }
    }, false);
    ////End///

  }

  ngOnInit() {
    this.getUserDetail();
    this.utils.confirmationDialogSubject.subscribe(({
      title,
      description,
      confirmationCallback,
      confirmButtonText,
      cancelButtonText
  }) => {
      this.confirmationDialogComponent.show(title, description, confirmationCallback, confirmButtonText, cancelButtonText);
  });
  }

  getUserDetail(): void {
    this.userService.updateUserDetails.subscribe(() => {
      this.user = this.userService.getUserDetails();
    });
    this.user = this.userService.getUserDetails();
  }

  updateUserDetails(): void {
    if (this.user) {
      try {
        const req ={
           userId: this.user.id 
        }
        const response =  this.userService.getCurrentUserDetails(req);
        localStorage.setItem('currentUser', JSON.stringify(
          response
        ));
      } catch (error) {
        this.utilService.notifyError(error);
      }
    }
  }



  checkRouting():void {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.isLogin = (this.userService.getUserDetails()) ? false : true;
        this.isLogin = val.url.includes('register-tenant') ? true : this.isLogin;
      }
    });
  }

  checkRoutingForSignature():void {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.isSignatureForm = val.url.includes('/swms-report/signature/') ? true : false;
      }
    });
  }

  checkRoutingForWorkerSignature():void {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
      let url=  window.location.pathname;
      // url  = localStorage.getItem('docUrl');
      console.log('val.url',val.url)
        this.isSignatureForm = val.url.includes('swms-report') ? true : false;
        console.log('his.isSignatureForm',this.activatedRoute.snapshot)
        if(this.isSignatureForm){
          const encodedURL = this.activatedRoute.snapshot.queryParamMap.get('returnUrl');
          console.log()
          // let docId  = this.activatedRoute.snapshot.queryParamMap.get('docId');
          // let docFormId = this.activatedRoute.snapshot.queryParamMap.get('docFormId');
          // localStorage.setItem("docUrl",encodedURL)
          // console.log('url',docId,docFormId)
        }
      }
    });
  }
}
