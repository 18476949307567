import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/shared/services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class TenantGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private myRouter: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const currentRole = this.userService.getSelectRole();
    console.log('state.8888')
   
    if (currentRole && (currentRole.role_id == 2 || currentRole.role_id == 3 || currentRole.role_id == 4)) {
      let docUrl = state.root.queryParams;
      if(docUrl && docUrl.tenantId){
          localStorage.setItem("docId",docUrl.docId);
          localStorage.setItem("docFormId", docUrl.docFormId);
          localStorage.setItem("tenantId", docUrl.tenantId);
      }
      return true;
    } else {
      this.myRouter.navigate(['/dashboard'], {queryParams: {returnUrl: state.url}});
      return false;
    }
  }
}
