import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { Utils } from '../../utils/utils';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthenticationService, public utils: Utils) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status == 401) {
        this.showModel();
      }
      const error = err.error.message || err.statusText;
      return throwError(error);
    }));
  }


  private showModel():void{
    this.utils.showConfirmationDialog('User not found. Check sign-in details are correct.', '', async () => {
      this.authService.logout();
      window.location.reload();
    },'OK','');
  }
}
