import {Routes} from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password.component';


export const ForgotPasswordRoutes: Routes = [
    {
        path: '',
        component: ForgotPasswordComponent,
        pathMatch: 'full',
    },
  
  
];
