import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanDeactivate, ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import { UserService } from 'src/app/shared/services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private myRouter: Router,
    private activatedRoute: ActivatedRoute) {
  }

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const currentRole = this.userService.getSelectRole();
    console.log('state.2222')
    if (currentRole && (currentRole.role_id == 1 || currentRole.role_id == 2 || currentRole.role_id == 3 || currentRole.role_id == 4)) {
      return true;
    } else {

      let docUrl = state.root.queryParams.returnUrl;
      console.log('docUrl', docUrl);
      if(docUrl){
     
        const queryString = docUrl.split('?')[1];
        const queryParams = new URLSearchParams(queryString);
        
        let docId = queryParams.get('docId');
        let docFormId = queryParams.get('docFormId');
        let tenantId = queryParams.get('tenantId');
        
        let isSignatureForm = docUrl.includes('swms-report') ? true : false;
        console.log('isSignatureForm',tenantId, docId,docFormId);
        
        if (isSignatureForm && tenantId) {
          localStorage.setItem("docId", docId);
          localStorage.setItem("docFormId", docFormId);
          localStorage.setItem("tenantId", tenantId);
        }
      }
 
      this.myRouter.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }
}

