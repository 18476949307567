import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { FormatAmountPipe } from './format-amount.pipe';
import { MinuteSecondsPipe } from './minute-seconds.pipe';




@NgModule({
    declarations: [
        FormatAmountPipe,
        MinuteSecondsPipe
    ],
    imports: [
        CommonModule,
       
    ],
    exports: [
        FormatAmountPipe,
        MinuteSecondsPipe
    ]
})
export class SharedModule {
}
