import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './others/guards/auth/admin.guard';
import { AuthGuard } from './others/guards/auth/auth.guard';
import { LoginGuard } from './others/guards/auth/login.guard';
import { TenantGuard } from './others/guards/auth/tenant.guard';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/logout/logout.component';






const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
  // { path: 'otp-verification', component: OtpValidationComponent, canActivate: [LoginGuard] },
  //{ path: 'app-payment', component: AppPaymentModule, canActivate: [LoginGuard] },
  { path: 'logout', component: LogoutComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [LoginGuard] },
  { path: 'resetpassword/:code', component: ForgotPasswordComponent, canActivate: [LoginGuard] },
  {
    path: 'app-payment',
    loadChildren: () => import('./pages/app-payment/app-payment.module').then(m => m.AppPaymentModule),

  },
  {
    path: 'worker-register',
    loadChildren: () => import('./pages/register-worker/register-worker.module').then(m => m.RegisterWorkerModule),

  },
  {
    path: 'worker-subscription',
    loadChildren: () => import('./pages/subscription-worker/subscription-worker.module').then(m => m.SubscriptionWorkerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'otp-verification',
    loadChildren: () => import('./pages/otp-validation/otp-validation.module').then(m => m.OtpValidationModule),

  },

  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashbaord/dashbaord.module').then(m => m.DashbaordModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'register-tenant/:id/:isSubScription',
    loadChildren: () => import('./pages/register-tenant/register-tenant.module').then(m => m.RegisterTenantModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'tenant-list',
    loadChildren: () => import('./pages/tenant/tenant.module').then(m => m.TenantModule),
    canActivate: [AdminGuard]
  },
  {
    path: 'hazard-category',
    loadChildren: () => import('./pages/general/hazard-category/hazard-category.module').then(m => m.HazardCategoryModule),
    canActivate: [AdminGuard]
  },

  {
    path: 'risk-assessment-category',
    loadChildren: () => import('./pages/general/risk-assessment-category/risk-assessment-category.module').then(m => m.RiskAssessmentCategoryModule),
    canActivate: [AdminGuard]
  },

  {
    path: 'worker-list',
    loadChildren: () => import('./pages/all-member-list/all-member-list.module').then(m => m.AllMemberListModule),
    canActivate: [AdminGuard]
  },
  {
    path: 'configure',
    loadChildren: () => import('./pages/configure-price/configure-price.module').then(m => m.ConfigurePriceComponentModule),
    canActivate: [AdminGuard]
  },


  {
    path: 'member-list',
    loadChildren: () => import('./pages/member-list/member-list.module').then(m => m.MemberListModule),
    canActivate: [TenantGuard]
  },


  {
    path: 'member-subscription',
    loadChildren: () => import('./pages/member-subscription/member-subscription.module').then(m => m.MemberSubscriptionModule),
    canActivate: [TenantGuard]
  },

  {
    path: 'client-list',
    loadChildren: () => import('./pages/client/client.module').then(m => m.ClientModule),
    canActivate: [TenantGuard]
  },

  {
    path: 'client-job-list',
    loadChildren: () => import('./pages/client-job/client-job.module').then(m => m.ClientJobModule),
    canActivate: [TenantGuard]
  },

  {
    path: 'swms-report',
    loadChildren: () => import('./pages/swms-report/swms-report.module').then(m => m.SwmsReportModule),
    canActivate: [TenantGuard]
  },
  {
    path: 'swms-units',
    loadChildren: () => import('./pages/swms-report-units/swms-report-units.module').then(m => m.SwmsReportUnitsModule),
    canActivate: [TenantGuard]
  },

  // {
  //   path: 'payment',
  //   loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule),
  //   // canActivate: [AuthGuard]
  // },

  {
    path: 'document',
    loadChildren: () => import('./pages/document/document.module').then(m => m.DocumentModule),
    canActivate: [TenantGuard]
  },

  {
    path: 'hazard',
    loadChildren: () => import('./pages/document/hazards-document/hazard-document.module').then(m => m.HazardDocumentModule),
    canActivate: [TenantGuard]
  },

  {
    path: 'risk',
    loadChildren: () => import('./pages/document/risk-document/risk-document.module').then(m => m.RiskDocumentModule),
    canActivate: [TenantGuard]
  },

  {
    path: 'category',
    loadChildren: () => import('./pages/document-category/document-category.module').then(m => m.DocumentCategoryModule),
    canActivate: [TenantGuard]
  },

  {
    path: 'induction',
    loadChildren: () => import('./pages/induction/induction.module').then(m => m.InductionModule),
    canActivate: [TenantGuard]
  },

  {
    path: 'log-history',
    loadChildren: () => import('./pages/log-history/log-history.module').then(m => m.LogHistoryModule),
    canActivate: [TenantGuard]
  },

  {
    path: 'subscription-history',
    loadChildren: () => import('./pages/subscription-history/subscription-history.module').then(m => m.SubscriptionHistoryModule),
    canActivate: [TenantGuard]
  },

  {
    path: 'profile-new',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'change-password',
    loadChildren: () => import('./pages/change-password/change-password.module').then(m => m.ChangePasswordModule),
    canActivate: [TenantGuard]
  },

  // {
  //   path: 'app-payment',
  //   loadChildren: () => import('./pages/app-payment/app-payment.module').then(m => m.AppPaymentModule),
  //   canActivate: [TenantGuard]
  // },


  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
