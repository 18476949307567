import { Injectable } from '@angular/core';
import { SubscriptionPlan } from 'src/app/shared/models';
import { ApiService } from 'src/app/shared/services/api.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PayementService {

  constructor(private userService: UserService, private apiService: ApiService,) {
  }

  public getPaymentIntent(): Promise<any> {
    return this.apiService.get<any>(`/getPaymentIntent`);
  }

  public getSubscriptionPlanList(): Promise<{ monthly: SubscriptionPlan[], yearly: SubscriptionPlan[] }> {
    return this.apiService.get<{ monthly: SubscriptionPlan[], yearly: SubscriptionPlan[] }>('/subscription/plan');
  }

  public getPlanList(req?:any): Promise<any> {
    return this.apiService.post<any>(`/planList`, req);
  }

  public createNewTenantSubscription(name: string, paymentMethodId: string, priceId: string,userId:number): Promise<any> {
    return this.apiService.post<any>(`/newTenantSubscription`, { name, paymentMethodId, priceId,userId });
  }

  public createSubscription(subscriptionDetails:any): Promise<any> {
    return this.apiService.post<any>(`/createSubscription`, subscriptionDetails);
  }

  public getSubscriptionActiveList(tenantId:any): Promise<any> {
    return this.apiService.post<any>('/getSubscriptionActive',{tenantId});
  }

  public cancelSubscription(request:any): Promise<any> {
    return this.apiService.post<any>(`/cancelSubscription`, request);
  }

  public unitPaymentIntent(request:any): Promise<any> {
    return this.apiService.post<any>(`/paymentIntent`, request);
  }

  public swmsUnitsPurchage(request:any): Promise<any> {
    return this.apiService.post<any>(`/swmsUnitsPurchase`, request);
  }

  public workerCancelSubscription(request:any): Promise<any> {
    return this.apiService.post<any>(`/workerSubscriptionCancel`,request);
  }

  public changeSubscription(paymentMethodId: string, newPriceId: string, subscriptionId: string): Promise<any> {
    const requestBody = {
      paymentMethodId,
      newPriceId,
      subscriptionId
    };
    return this.apiService.post<any>('/subscription/change', requestBody);
  }

 
  public getSubscriptionHistory(req?:any): Promise<any> {
    return this.apiService.post<any>(`/subscription/history`, req);
  }
}
