import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TenantListModel } from 'src/app/models/tenant_list.model';
import { Utils } from 'src/app/others/utils/utils';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { UserService } from 'src/app/shared/services/user/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user: any;
  userName!: string;
  tenantList: any;
  currentTenant!: TenantListModel | null;
  userRoles: any[];
  currentUserRole: any;
  selectRole: any;
  extistTenant: any;
  swmsNotification: boolean = false;
  hazardNotification: boolean = false;
  notificationCount: number = 0;
  showNotificationModel: boolean = false;
  notificationList:any


  constructor(
    private userService: UserService,
    private router: Router,
    private authService: AuthenticationService,
    public utils: Utils,
  ) {
    this.userName = '';
    this.tenantList = [];
    this.userRoles = [];
  }

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    this.user = this.userService.getUserDetails();
    this.userName = this.user.firstname;
    await this.getTenantList();
    console.log('this.tenantList111333', this.tenantList)

    await this.getNotificationList();
  }

  async getTenantList(): Promise<void> {
    this.selectRole = this.userService.getSelectRole();
    let tenantId = localStorage.getItem('tenantId');

 
    if (this.selectRole.role_id === 1) {
      this.currentTenant = this.selectRole;
    } else {
      const response = localStorage.getItem('userRoleList');
      this.tenantList = JSON.parse(response);
      if (tenantId) {
        const index = this.tenantList.findIndex(item => item.id == tenantId);
  
        // If a matching tenant is found, move it to the beginning of the array
        if (index !== -1) {
          const matchedTenant = this.tenantList.splice(index, 1)[0];
          // Insert the matched tenant at the beginning of the array
          this.tenantList.unshift(matchedTenant);
        }
        console.log('currentTenant333', this.currentTenant)
  
      }
      if (this.tenantList && this.tenantList.length > 0) {
        this.currentTenant = this.tenantList.filter(item => {
          return item.id === this.user.id
        })[0];
        if (this.selectRole) {
          this.currentTenant = this.selectRole;
        } else {
          this.currentTenant = this.tenantList.filter(item => {
            return item.id === this.user.id;
          })[0];
          this.userService.setCurrentTenant(this.currentTenant);
        }
      }
    }
   

    const currentTenant = this.userService.getCurrentTenant();
    if ((this.tenantList && this.tenantList.length > 0) && (currentTenant && !currentTenant.isAccess)) {
      this.utils.showConfirmationDialog('Principal contractor has disconnected so please talk with his.', '', async () => {
      }, 'OK');
    }
    //  else if (this.selectRole.role_id === 4) {
    //   this.utils.showConfirmationDialog('Trade worker is not authorised to access the web portal. please try login into the application.', '', async () => {
    //   }, 'OK');
    // }
    if(tenantId){
      await this.userService.setSelectRole(this.tenantList[0]);
      this.currentTenant=this.tenantList[0];

    }

  }

  async getUserRoleList(): Promise<void> {
    const selectRole = this.userService.getSelectRole();
    this.userRoles = this.user.userRoles;
    this.currentUserRole = selectRole;
  }

  sidebarHide(): void {
    const bodyTag = document.body;
    bodyTag.classList.toggle('sidebar-hide');
    const isHide = localStorage.getItem('hideSidemenu');
    localStorage.setItem('hideSidemenu', (isHide && isHide.includes('true')) ? 'false' : 'true');
  }

  getTitle(): string {
    const url = this.router.url.split('/')[1].toLowerCase();
    switch (url) {
      case 'dashboard':
        return 'Dashboard';
      case 'client-list':
        return 'Clients';
      case 'client-job-list':
        return 'Jobs'
      case 'category':
        return 'Document Category'
      case 'document':
        return 'Documents';
      case 'hazard':
        return 'Hazards Report';
      case 'risk':
        return 'Risk Assesments';
      case 'swms-report':
        return 'Document';
      case 'swms-units':
        return 'SWMS Units';
      case 'member-list':
        return 'Trade Worker';
      case 'member-subscription':
        return 'Trade Worker Subscription';
      case 'subscription-history':
        return 'Subscription History';
      case 'induction':
        return 'Induction';
      case 'tenant-list':
        return 'Principal Contractors';
      case 'hazard-category':
        return 'Hazard Category'
      case 'tenant-detail':
        return 'Tenant Details';
      case 'risk-assessment-category':
        return 'Risk Assessment Category'
      case 'member-list':
        return 'Trade Worker List';
      case 'configure':
          return 'Price Configuration';
      case 'member-subscription':
        return 'Trade WorkerSubscription';
      case 'subscriptions':
        return 'Subscriptions';
      case 'profile':
        return 'Profile';
      default:
        return '';
    }
  }

  doLogout(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
    // window.location.reload();
  }

  upgradeSubscription(): void {
    this.router.navigate(['subscriptions']);
  }



  getUserDetails(): void {
    this.user = this.userService.getUserDetails();
  }

  async switchTenant(value: any): Promise<void> {
    await this.userService.setCurrentTenant(value);
    this.user = this.userService.getUserDetails();
    this.currentTenant = value;
    if (value.role_name === 'Worker') {
      localStorage.removeItem('currentTenant');
    }
    await this.userService.setSelectRole(value);
    this.router.navigate(['/profile-new']);
    setTimeout(() => {
      window.location.reload();
    }, 20);
  }

  getUserImage(item: TenantListModel): string {
    return '/assets/images/user.png';
  }
  gotoProfile() {
    this.router.navigate(['/profile']);
  }
  goToChangePassword() {
    this.router.navigate(['change-password']);
  }

  async getNotificationList(): Promise<void> {

    try {
      const request = {
        roleId: 1
      }
      const response = await this.userService.getNotification(request);

      this.notificationList = response.data.records;
      const newNotification = response.data.records.filter(item => {
        return item.is_show == 1;
      });

      if (this.notificationList.length > 0 || this.notificationList.length > 0) {
        this.showNotificationModel = true;
      }else{
        this.showNotificationModel = false;
      } 
      if (newNotification.length > 0) {
        this.swmsNotification = true;
        this.notificationCount = this.notificationCount + 1;
      }
      
    } catch (error) {
      console.error(error)
    }

  }

  async hideNotification(): Promise<void> {
    try {
      const request = {
        roleId: 1
      }
      await this.userService.updateNotification(request);
      await this.getNotificationList();
      this.notificationCount = 0;
    } catch (error) {
      console.error(error)
    }

  }

  async deleteNotification(): Promise<void> {
    try {
      const request = {
        roleId: 1
      }
      await this.userService.deleteNotification(request);
      await this.getNotificationList();
    } catch (error) {
      console.error(error)
    }

  }
}
