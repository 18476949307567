import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/shared/services/util.service';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(private httpClient: HttpClient,
        private router: Router, private utilService: UtilService) {
    }

    get<T>(url: string, options?: Option): Promise<T> {
        url = this.prepareUrl(url);
        // if (!(url.includes('login') || url.includes('forgotpassword') || url.includes('verifyOTP') || url.includes('uploadFile') || url.includes('newTenantSubscription') || url.includes('register/tenant')) || url.includes('getNotification') || url.includes('planList')) {
        //     const currentUser:any = localStorage.getItem('currentUser');
        //     if(!currentUser) {
        //         this.utilService.notifyError('Authentication token is not available, try login again');
        //         localStorage.clear();
        //         this.router.navigate(['/login']);
        //         return
        //     }
        // }
        return this.httpClient.get<T>(url, options)
            .toPromise()
            .then(resp => {
                if (resp && (resp as any).errorMessage) {
                    throw new Error((resp as any).errorMessage);
                } else {
                    return resp;
                }
            });
    }

    delete<T>(url: string, options?: Option): Promise<T> {
        url = this.prepareUrl(url);
        // if (!(url.includes('login') || url.includes('forgotpassword') || url.includes('verifyOTP') || url.includes('uploadFile') || url.includes('newTenantSubscription') || url.includes('register/tenant')) || url.includes('getNotification') || url.includes('planList')) {
        //     const currentUser:any = localStorage.getItem('currentUser');
        //     if(!currentUser) {
        //         this.utilService.notifyError('Authentication token is not available, try login again');
        //         localStorage.clear();
        //         this.router.navigate(['/login']);
        //         return
        //     }
        // }
        return this.httpClient.delete<T>(url, options)
            .toPromise()
            .then(resp => {
                if (resp && (resp as any).errorMessage) {
                    throw new Error((resp as any).errorMessage);
                } else {
                    return resp;
                }
            });
    }

    post<T>(url: string, body: any | null, options?: Option): Promise<T> {
        url = this.prepareUrl(url);
        // if (!(url.includes('login') || url.includes('forgotpassword') || url.includes('verifyOTP') || url.includes('uploadFile') || url.includes('newTenantSubscription') || url.includes('register/tenant')) || url.includes('getNotification') || url.includes('planList')) {
        //     const currentUser:any = localStorage.getItem('currentUser');
        //     if (!currentUser) {
        //         this.utilService.notifyError('Authentication token is not available, try login again');
        //         localStorage.clear();
        //         this.router.navigate(['/login']);
        //         return
        //     }
        // }
        return this.httpClient.post<T>(url, body, options)
            .toPromise()
            .then(resp => {
                if (resp && (resp as any).errorMessage) {
                    throw new Error((resp as any).errorMessage);
                } else {
                    return resp;

                }
            });
    }

    put<T>(url: string, body: any | null, options?: Option): Promise<T> {
        url = this.prepareUrl(url);
        return this.httpClient.put<T>(url, body, options)
            .toPromise()
            .then(resp => {
                if (resp && (resp as any).errorMessage) {
                    throw new Error((resp as any).errorMessage);
                } else {
                    return resp;
                }
            });
    }

    private prepareUrl(url: string): string {
        if (!(url.startsWith('http://') || url.startsWith('https://'))) {
            url = environment.apiEndpoint + url;
        }
        return url;
    }
}

interface Option {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
}
