import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { UtilService } from 'src/app/shared/services/util.service';

declare var $;
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  user: any;
  currentRole: any;
  menuName = '';
  selectRole: any;
  workerMenu = '';
  menu: any = [
    {
      mainMenu: 'Dashboard',
      routerLink: 'dashboard',
      isActive: false,
      subMenu: [],
      icon: 'assets/images/dashboard.svg',
    },
    {
      mainMenu: 'Clients',
      routerLink: 'client-list',
      isActive: false,
      subMenu: [],
      icon: 'assets/images/client.svg',
    },
    {
      mainMenu: 'Jobs',
      routerLink: 'client-job-list',
      isActive: false,
      subMenu: [],
      icon: 'assets/images/jobs.svg',
    },
    {
      mainMenu: 'Documents',
      isActive: false,
      icon: 'assets/images/document.svg',
      subMenu: [
        {
          name: 'Document Category',
          routerLink: 'category',
          isActive: false,
        },
        {
          name: 'Documents',
          routerLink: 'document',
          isActive: false,
        },
        // {
        //   name: 'Documents Management',
        //   routerLink: 'document/management',
        //   isActive: false
        // },
        {
          name: 'Hazard Reports',
          routerLink: 'hazard',
          isActive: false,
        },
        {
          name: 'Risk Assessment',
          routerLink: 'risk',
          isActive: false,
        },
      ],
    },
    {
      mainMenu: 'SWMS',
      isActive: false,
      icon: 'assets/images/swms.svg',
      subMenu: [
        {
          name: 'SWMS Units',
          routerLink: 'swms-units',
          isActive: false,
        },
        {
          name: 'SWMS Document',
          routerLink: 'swms-report',
          isActive: false,
        },
      ],
    },
    {
      mainMenu: 'Subscription',
      icon: 'assets/images/member.svg',
      isActive: false,
      subMenu: [
        {
          name: 'Worker Subscriptions',
          routerLink: 'member-subscription',
          isActive: false,
        },
        {
          name: 'Own Subscriptions',
          routerLink: 'subscription-history',
          icon: 'assets/images/payment_history.svg',
          isActive: false,
        },
      ],
    },

    // {
    //   mainMenu: 'Trade Worker',
    //   icon: 'assets/images/member.svg',
    //   isActive: false,
    //   subMenu: [
    //     {
    //       name: 'Trade Worker List',
    //       routerLink: 'member-list',
    //       isActive: false
    //     },
    //     {
    //       name: 'Trade Worker Subscriptions',
    //       routerLink: 'member-subscription',
    //       isActive: false
    //     }
    //   ],
    // },
    {
      mainMenu: 'Trade Worker',
      routerLink: 'member-list',
      icon: 'assets/images/member.svg',
      isActive: false,
      subMenu: [],
    },
    {
      mainMenu: 'Induction',
      routerLink: 'induction',
      icon: 'assets/images/induction.svg',
      isActive: false,
      subMenu: [],
    },

    {
      mainMenu: 'Log History',
      routerLink: 'log-history',
      icon: 'assets/images/log-history.svg',
      isActive: false,
      subMenu: [],
    },
  ];
  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthenticationService,
    private utilService: UtilService
  ) {
    this.selectRole = this.userService.getSelectRole();
    this.getUserDetails();
    const url = this.router.url.split('/')[1];
    for (let item of this.menu) {
      for (let sub of item.subMenu) {
        if (sub.routerLink === url) {
          item.isActive = true;
        }
      }
      if (item.routerLink === url) {
        item.isActive = true;
      }
    }
    if (this.isSupervisor()) {
      this.menu = this.menu.filter((item) => {
        return item.routerLink != 'subscription-history';
      });
      this.menu.forEach((item) => {
        item.subMenu = item.subMenu.filter((menu) => {
          return (
            menu.name != 'SWMS Units' &&
            menu.name != 'Trade Worker Subscriptions'
          );
        });
      });
    }

    if (this.isWorkerRole()) {
      this.menu = this.menu.filter((item) => {
        return item.routerLink != 'subscription-history';
      });
      this.menu.forEach((item) => {
        item.subMenu = item.subMenu.filter((menu) => {
          return (
            menu.name != 'SWMS Units' &&
            menu.name != 'Trade Worker Subscriptions'
          );
        });
      });
    }

    if (this.user.type === this.utilService.userRole[2].type) {
      const workerMenu = [
        {
          mainMenu: 'SWMS',
          isActive: false,
          icon: 'assets/images/swms.svg',
          subMenu: [
            {
              name: 'SWMS Document',
              routerLink: 'swms-report',
              isActive: false,
            },
            {
              name: 'SWMS Units',
              routerLink: 'swms-units',
              isActive: false,
            },
          ],
        },
        {
          mainMenu: 'Documents',
          isActive: false,
          icon: 'assets/images/swms.svg',
          subMenu: [
            {
              name: 'Documents',
              routerLink: 'document',
              isActive: false,
            },
            {
              name: 'Hazard Reports',
              routerLink: 'hazard',
              isActive: false,
            },
            {
              name: 'Risk Assessment',
              routerLink: 'risk',
              isActive: false,
            },
          ],
        },
        {
          mainMenu: 'Trade Worker',
          routerLink: 'member-list',
          icon: 'assets/images/member.svg',
          isActive: false,
          subMenu: [],
        },
      ];
      this.menu = workerMenu;
    } else if (this.user.type === this.utilService.userRole[3].type) {
      const workerMenu = [
        {
          mainMenu: 'SWMS',
          isActive: false,
          icon: 'assets/images/swms.svg',
          subMenu: [
            {
              name: 'SWMS Document',
              routerLink: 'swms-report',
              isActive: false,
            },
          ],
        },
        {
          mainMenu: 'Documents',
          isActive: false,
          icon: 'assets/images/swms.svg',
          subMenu: [
            {
              name: 'Documents',
              routerLink: 'document',
              isActive: false,
            },
            {
              name: 'Hazard Reports',
              routerLink: 'hazard',
              isActive: false,
            },
            {
              name: 'Risk Assessment',
              routerLink: 'risk',
              isActive: false,
            },
          ],
        },
      ];
      this.menu = workerMenu;
    } else {
      this.menu = this.menu;
    }

    // if (this.user.isTenant === 0) {
    //   const workerMenu = [{
    //     mainMenu: 'Documents',
    //     isActive: false,
    //     icon: 'assets/images/swms.svg',
    //     subMenu: [
    //       {
    //         name: 'Hazard Reports',
    //         routerLink: 'hazard',
    //         isActive: false
    //       },
    //       {
    //         name: 'Risk Assessment',
    //         routerLink: 'risk',
    //         isActive: false
    //       }
    //     ],
    //   }]
    //   this.menu = workerMenu;
    // }
  }

  ngOnInit(): void {
    if (this.hideSidemenu()) {
      const bodyTag = document.body;
      bodyTag.classList.toggle('sidebar-hide');
    }
  }

  hideSidemenu(): boolean {
    const item = localStorage.getItem('hideSidemenu');
    return !!(item && item.includes('true'));
  }

  getHighlightClass(
    item: string = '',
    index: number,
    subMenuIndex: number,
    menuName
  ) {
    this.workerMenu = item;
    if(this.menu){
      for (let menu of this.menu) {
        menu.isActive = false;
        if (menu.subMenu.length > 0) {
          for (let subMenu of menu.subMenu) {
            subMenu.isActive = false;
          }
        }
      }
    }
    
    setTimeout(() => {
      if(this.menu){
        if (this.menu[index].subMenu.length > 0) {
          if (this.menu[index].subMenu[subMenuIndex]) {
            this.menu[index].subMenu[subMenuIndex].isActive = true;
          }
          this.menu[index].isActive = true;
        }
        if (this.menu[index].subMenu.length === 0) {
          this.menu[index].isActive = true;
        }
      }
      
    }, 0);
  }

  getHighlightClassNew(item: string = ''): string {
    let myClass: string;
    myClass = 'nav-item';
    let highLightClass: string;
    highLightClass = ' active';
    return this.router.url.includes('tenant') && item.includes('tenant')
      ? myClass + highLightClass
      : this.router.url.includes(item)
      ? myClass + highLightClass
      : myClass;
  }

  getUserDetails(): void {
    this.user = this.userService.getUserDetails();
  }
  activeMenu(index: number) {}
  isMasterAdmin(): boolean {
    return this.getCurrentRole() === 1;
  }

  getCurrentRole(): number {
    if (!this.currentRole) {
      this.currentRole = this.userService.getSelectRole();
    }
    return this.currentRole ? this.currentRole.role_id : 4;
  }

  isTenantAdmin(): boolean {
    return this.getCurrentRole() === 2;
  }

  isSupervisor(): boolean {
    return this.getCurrentRole() === 3;
  }

  isWorkerRole(): boolean {
    return this.getCurrentRole() === 4;
  }

  doLogout(): void {
    this.authService.logout();
    // this.router.navigate(['login']);
    window.location.reload();
  }
  hazardCategory() {
    this.router.navigate(['/hazard-category']);
  }

  addClassForDocument(menuName) {
    if (menuName == 'Sign out') {
      this.doLogout();
    }
    if (this.menuName == menuName) {
      this.menuName = '';
    } else {
      this.menuName = menuName;
    }
  }
}
