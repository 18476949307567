import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ProfileRoutes } from './profile-router';
import { PaymentModule } from '../payment/payment.module';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [ProfileComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule.forChild(ProfileRoutes),
    PaymentModule
  ],
  
})
export class ProfileModule { }
