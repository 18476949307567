import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';

declare var $: any;
@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

    title?: string;
    description?: string;
    confirmButtonText: string = 'Confirm';
    cancelButtonText: string = 'Cancel';
    confirmationCallback: () => void;

    constructor(private elementRef: ElementRef) {
    }

    ngOnInit() {
    }

    show(title: string, description: string, confirmationCallback: () => void, confirmButtonText: string, cancelButtonText: string) {
        this.title = title;
        this.description = description;
        this.confirmButtonText = confirmButtonText;
        this.cancelButtonText = cancelButtonText;
        this.confirmationCallback = confirmationCallback;

        $(this.elementRef.nativeElement)
            .find('#alert_box')
            .modal('show');
    }
}
