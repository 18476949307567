import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigurePriceService {
  user: any;
  clientTrigger: Subject<any> = new Subject<any>();

  constructor(private userService: UserService,private apiService: ApiService,) {
    this.user = this.userService.getUserDetails();
  }

  getConfigurationList(request?:any): Promise<any> {
    return this.apiService.post(`${environment.API}/priceConfig`, request);
  }

  
  public updateClientById(request:any): Promise<any> {
    return this.apiService.post<any>(`/updateConfig`,request);
  }
  public configList(request:any): Promise<any> {
    return this.apiService.post<any>(`/config`,request);
  }
}
