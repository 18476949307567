import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TenantListModel } from 'src/app/models/tenant_list.model';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  updateUserDetails: Subject<any> = new Subject<any>();
  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // loaderService: Subject<{ show?: boolean, message?: string }> = new Subject<{ show?: boolean, message?: string }>();
  confirmationDialogSubject: Subject<{ title: string, description: string, confirmationCallback: () => void, confirmButtonText: string, cancelButtonText: string }> = new Subject<{ title: string, description: string, confirmationCallback: () => void, confirmButtonText: string, cancelButtonText: string }>();
  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  public async presentLoading() {
    this.loading.next(false);
  }

  public async dismissLoading() {
    this.loading.next(true);
  }

  getUserDetails() {
    let user: any = localStorage.getItem('currentUser');
    if (user) {
      user = JSON.parse(user);
    }
    return user;
  }

  updateProfile(request: any): Promise<any> {
    return this.apiService.post(`${environment.API}/updateProfile`, request);
  }

  // getUserDetailsFromApi(request: any): Observable<any> {
  //   return this.http.post(`${environment.API}/userdetail`, request).pipe(map((item: any) => {
  //     return item.data;
  //   }));
  // }

  getCurrentUserDetails(data: any): Promise<any> {
    return this.apiService.post(`${environment.API}/userdetail`, data);
  }

  getTenantList(data: any): Promise<any> {
    return this.apiService.post(`${environment.API}/tenants/getTenants`, data);
  }


  otpVerification(data: any): Promise<any> {
    return this.apiService.post(`${environment.API}/verifyOTP`, data);
  }

  getUserTenantList(): Promise<any> {
    return this.apiService.get(`${environment.API}/getMyTenants`);

  }

  setCurrentTenant(currentTenant: TenantListModel): void {
    localStorage.setItem('currentTenant', JSON.stringify(currentTenant));
  }

  getCurrentTenant(): any | null {
    let item: any = localStorage.getItem('currentTenant');
    if (item) {
      item = JSON.parse(item);
    }
    return item;

  }
 

  setSelectRole(currentRole: any): void {
    localStorage.setItem('selectRole', JSON.stringify(currentRole));
  }

  getSelectRole(): any {
    const selectRole = localStorage.getItem('selectRole');
    return JSON.parse(selectRole);
  }

  public changePassword(request: any): Promise<any> {
    return this.apiService.post<any>(`/changePassword`, request);
  }

  public getNotification(req:any): Promise<any> {
    return this.apiService.post<any>(`/getNotification`, req);
  }

  public updateNotification(req:any): Promise<any> {
    return this.apiService.post<any>(`/updateNotification`, req);
  }
  public deleteNotification(req:any): Promise<any> {
    return this.apiService.post<any>(`/deleteNotification`, req);
  }
}
