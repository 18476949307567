import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Utils } from 'src/app/others/utils/utils';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { PayementService } from '../payment/payment.service';
import { TenantService } from '../tenant/tenant.service';
import { ActiveSubscription } from '../../shared/models/index';
import { UtilService } from 'src/app/shared/services/util.service';
import { PaymentComponent } from '../payment/payment.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigurePriceService } from '../configure-price/configure-price.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @ViewChild('paymentComponentPopup', { static: true }) paymentComponent: PaymentComponent;
  user: any;
  profileForm!: FormGroup;
  isProfilePicChange: boolean;
  isUserDetailFatched: boolean;
  isDisable: boolean;
  tenantSubscriptionDetails: any;
  workerSubscriptionList: any;
  planList: any;
  isSupportedFile: boolean = false;

  fileData!: File;
  previewUrl: string | ArrayBuffer;
  @ViewChild('fileInput', { static: false })
  myFileInput!: ElementRef;
  showError: boolean;
  activeSubscription: ActiveSubscription;
  subscriptionId: any;
  isChangePlan = false;
  fileName: any;
  selectFile: any;
  userDetails: any;
  leftDays: any;
  pcDetails:any;

  constructor(private fromBuilder: FormBuilder,
    private userService: UserService,
    private tenantService: TenantService,
    private authService: AuthenticationService,
    private router: Router,
    private utils: Utils,
    private paymentService: PayementService,
    private utilService: UtilService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private configurePriceService:ConfigurePriceService

  ) {

    this.showError = false;
    this.isProfilePicChange = false;
    this.isUserDetailFatched = false;
    this.isDisable = false;
    this.previewUrl = '';
  }

  ngOnInit(): void {

    this.profileForm = this.fromBuilder.group({
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      phone: new FormControl('', []),
      email: new FormControl({ value: '', disabled: true }),
      mobile: new FormControl('', []),
      name: new FormControl('', []),
      url: new FormControl('', []),
      address: new FormControl('', []),
      state: new FormControl('', []),
      city: new FormControl('', []),
      country: new FormControl('', []),
      currentPassword: new FormControl(''),
      newPassword: new FormControl(''),
      confirmPassword: new FormControl('')
    });
    this.init();
  }

  async init() {
    this.userDetails = this.userService.getUserDetails();
    await this.getData();
    await this.configDetails();
    await this.getSubscriptionDetails();
    this.leftDays = ( this.pcDetails.interval - this.userDetails.diffInDays)
  }

  get f(): { [p: string]: AbstractControl } {
    return this.profileForm.controls;
  }

  async getData(): Promise<void> {
    try {
      let tenantId;
      const extistTenantList = this.userService.getCurrentTenant();
      if (extistTenantList) {
        tenantId = extistTenantList.id;
      } else {
        tenantId = this.userDetails.id
      }
      const req = {
        tenantId: tenantId
      }
      const response = await this.userService.getCurrentUserDetails(req);
      this.user = response.data;
      if (this.user) {
        this.profileForm.controls.firstname.setValue(this.user.firstname);
        this.profileForm.controls.lastname.setValue(this.user.lastname);
        this.profileForm.controls.email.setValue(this.user.email);
        this.profileForm.controls.mobile.setValue(this.user.phone);
        this.profileForm.controls.name.setValue(this.user.name);
        this.profileForm.controls.url.setValue(this.user.url);
        this.profileForm.controls.address.setValue(this.user.address);
        this.profileForm.controls.state.setValue(this.user.state);
        this.profileForm.controls.city.setValue(this.user.city);
        this.profileForm.controls.country.setValue(this.user.country);
        this.selectFile = this.user.profilePic;
      }

    } catch (error) {
      console.error('error', error);
    }


  }

  disableAll(): void {
    this.profileForm.get('firstname')?.disable();
    this.profileForm.get('lastname')?.disable();
    this.profileForm.get('email')?.disable();
    this.profileForm.get('mobile')?.disable();
    this.profileForm.get('name')?.disable();
    this.profileForm.get('url')?.disable();
    this.profileForm.get('address')?.disable();
    this.profileForm.get('state')?.disable();
    this.profileForm.get('city')?.disable();
    this.profileForm.get('country')?.disable();
    this.profileForm.get('currentPassword')?.disable();
    this.profileForm.get('newPassword')?.disable();
    this.profileForm.get('confirmPassword')?.disable();
    this.isDisable = true;
  }

  updateUserDetails(): void {
    try {


    } catch (error) {
      this.utilService.notifyError(error);
    }

  }

  fileProgress(fileInput: any): void {
    this.fileData = (fileInput.target.files[0] as File);
    this.preview();
  }

  preview(): void {
    // Show preview
    const mimeType = this.fileData.type;
    if (mimeType == 'image/png' || mimeType == 'image/jpg') {
      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = (_event: ProgressEvent<FileReader>) => {
        this.previewUrl = (reader.result && reader.result.toString().length > 0) ? reader.result : '';
        this.isProfilePicChange = true;
      };
    } else {
      this.utilService.notifyError('File is not supported.');
      this.previewUrl = '';
    }

  }

  clearSelection(): void {
    this.myFileInput.nativeElement.value = '';
    this.previewUrl = '';
    this.isProfilePicChange = true;
  }

  async onSubmit(): Promise<void> {
    await this.spinner.show();
    let request: any = {
      firstname: this.f.firstname.value,
      lastname: this.f.lastname.value
    };
    let profileImage = '';
    if (this.selectFile && this.selectFile.toString().startsWith('data:image')) {
      profileImage = this.selectFile.toString();
    }
    request = {
      ...request,
      phone: this.f.mobile.value,
      name: this.f.name.value,
      url: this.f.url.value,
      address: this.f.address.value,
      state: this.f.state.value,
      city: this.f.city.value,
      country: this.f.country.value,
      profileImage:profileImage
    };
    try {
      await this.userService.updateProfile(request);
      await this.utilService.notifySuccess('Profile details has been updated.');
      await this.getData();
      setTimeout(async () => {
        await this.spinner.hide();

      }, 4000);
    } catch (error) {
      this.utilService.notifyError(error);
    }
  }

  isAdmin(): boolean {
    if (this.user) {
      if (this.user.type) {
        return (this.user.type === 1);
      }
    }
    return true;
  }

  onCancel(event: any): void {
    event.preventDefault();
    this.router.navigate(['dashboard']);
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  private async getSubscriptionDetails() {
    const existTenant = this.userService.getSelectRole();
    let tenantId = 0;
    if (existTenant) {
      tenantId = existTenant.id
    } else {
      tenantId = this.user.id
    }
    const response = await this.paymentService.getSubscriptionActiveList(tenantId);
    if (response.data.activeSubscriptionList && response.data.activeSubscriptionList.length > 0) {
      this.tenantSubscriptionDetails = response.data.activeSubscriptionList.filter((item: any) => {
        return (item.price == 5500) || (item.price == 6490) || (item.price == 66000);
      })[0];

      this.workerSubscriptionList = response.data.activeSubscriptionList.filter((item: any) => {
        return (item.price != 5500) && (item.price != 6490) && (item.price != 66000);
      });
      this.activeSubscription = response.data.activeSubscriptionList;
    }

  }

  public async cancelSubscription(subscriptionId: number, isAllSubscription: boolean): Promise<void> {
    this.utils.showConfirmationDialog('Are you sure you want to cancel the subscription?',
      '',
      async () => {
        this.spinner.show();
        try {
          const existTenant = this.userService.getSelectRole();
          let tenantId = 0;
          if (existTenant) {
            tenantId = existTenant.id
          } else {
            tenantId = this.user.id
          }
          const request = {
            tenantId: tenantId,
            subscriptionId: Number(subscriptionId),
            isAllSubscription: isAllSubscription,
            isCancel: true
          }
          await await this.paymentService.cancelSubscription(request);
          this.utilService.notifySuccess('Your subscription has been cancelled.You can reactivate it anytime');
          setTimeout(() => {
            this.getSubscriptionDetails();
          }, 1500);
        } catch (err) {
          console.error(err);
          this.utilService.notifyError(err.message);
        }
        this.spinner.hide();
      });
  }


  public async subscriptionReActive(subscriptionId: number, isAllSubscription: boolean): Promise<void> {
    this.utils.showConfirmationDialog('Are you sure you want to reactivating the subscription?',
      'You can cancel it anytime you want.',
      async () => {
        this.spinner.show();
        try {
          const existTenant = this.userService.getSelectRole();
          let tenantId = 0;
          if (existTenant) {
            tenantId = existTenant.id
          } else {
            tenantId = this.user.id
          }
          const request = {
            tenantId: tenantId,
            subscriptionId: Number(subscriptionId),
            isAllSubscription: isAllSubscription,
            isCancel: false
          }
          await this.paymentService.cancelSubscription(request);
          this.utilService.notifySuccess('Congratulations! Your subscription is reactivated.');
          setTimeout(() => {
            this.getSubscriptionDetails();
          }, 1500);
        } catch (err) {
          console.error(err);
          this.utilService.notifyError(err.message);
        }
        this.spinner.hide();
      });
  }


  public downloadInvoice(invoicePdf: string): void {
    window.open(invoicePdf, '_blank');
  }

  paymentModal(subscriptionId: number) {
    this.isChangePlan = true;
    this.subscriptionId = subscriptionId;
    this.router.navigate(['/profile-new'], { relativeTo: this.activatedRoute, queryParams: { subscriptionId: subscriptionId } });
  }

  async updatePlan(): Promise<void> {
    this.isChangePlan = false;
    await this.getSubscriptionDetails();
  }
 

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.selectFile = event.target.result;
      }
    }
  }
  async configDetails():Promise <void> {
    try {
      const response:any =  await this.configurePriceService.configList('');
      this.pcDetails = response.data[2];
    } catch (error) {
      this.utilService.notifyError(error);
    }
}

}
