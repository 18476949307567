// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // production: false,
  // API: 'http://192.168.1.15:3000',
  // apiEndpoint: 'http://192.168.1.15:3000'

  // API: 'https://admin.hazardeye.com.au/api',
  // apiEndpoint: 'https://admin.hazardeye.com.au/api',
  // publicKey:'pk_live_51LczneLozjlJw4YsVu5bQ10FUNsEqYMpCYN0uWPoaD3iBmh1FaURtA2CGEJbJxuU8I2V7JPd2udlngORazCqDXJ9003FKSE6nc'

  // API: 'https://hazard.hcshub.in/api',
  // apiEndpoint: 'https://hazard.hcshub.in/api/',

  // API: 'https://admin.hazardeye.com.au/api',
  // apiEndpoint: 'https://admin.hazardeye.com.au/api',
  // publicKey:'pk_live_51LczneLozjlJw4YsVu5bQ10FUNsEqYMpCYN0uWPoaD3iBmh1FaURtA2CGEJbJxuU8I2V7JPd2udlngORazCqDXJ9003FKSE6nc'


  // production: true,
  // API: 'https://admin.hazardeye.com.au/api',
  // apiEndpoint: 'https://admin.hazardeye.com.au/api',
  // publicKey:'pk_live_51LczneLozjlJw4YsVu5bQ10FUNsEqYMpCYN0uWPoaD3iBmh1FaURtA2CGEJbJxuU8I2V7JPd2udlngORazCqDXJ9003FKSE6nc'

  // production: false,
  // API: 'http://localhost:4600',
  // apiEndpoint: 'http://localhost:4600',
  // publicKey:'pk_test_51JnLiRBZXyHvVMo3ac1Ityf8TsBA0KyrVJX9I2OUKgt8fURodEy43XUFy2CnO23mX2t3p3R7PvER0GX9sCh0EBtZ00WtsUHU83'

    production: false,
    API: 'https://hazardeye.hcshub.in/hazardeye-api',
    apiEndpoint: 'https://hazardeye.hcshub.in/hazardeye-api',
    publicKey:'pk_test_51JnLiRBZXyHvVMo3ac1Ityf8TsBA0KyrVJX9I2OUKgt8fURodEy43XUFy2CnO23mX2t3p3R7PvER0GX9sCh0EBtZ00WtsUHU83'
  


  // API: 'http://localhost:3000',
  // apiEndpoint: 'http://localhost:3000',

  // publicKey:'pk_test_51JnLiRBZXyHvVMo3ac1Ityf8TsBA0KyrVJX9I2OUKgt8fURodEy43XUFy2CnO23mX2t3p3R7PvER0GX9sCh0EBtZ00WtsUHU83'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
