import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Utils } from 'src/app/others/utils/utils';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { PasswordStrengthValidator } from '../register-tenant/password-strength.validators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  code: number = 0;
  resetForm!: FormGroup;
  showEmailError: boolean = false;
  showPasswordError: boolean = false;
  showConfirmPasswordError: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private utils: Utils,
    private utilService: UtilService,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.code = 0;
    this.resetForm = this.formBuilder.group({
      email: [''],
      password: ['', Validators.compose([
        Validators.required, Validators.minLength(8), PasswordStrengthValidator])],
      confirmPassword: ['', Validators.required],
    });
  }

  ngAfterViewInit() {
    if (this.isTokenAvail()) {
      this.authService.verifyForgotPasswordToken(this.code).subscribe(data => {
      }, error => {
        console.error('page', error);
        this.utilService.notifyError(error);
        this.navigateToLogin();
      });
    }

  }
  navigateToLogin() {
    this.router.navigate(['/login']);
  }

  isTokenAvail(): boolean {
    this.route.params.subscribe(async (params) => {
      this.code = params.code;
    });
    return this.code > 0;
  }

  get f() { return this.resetForm.controls };

  onSubmit() {
    this.showPasswordError = false;
    this.showEmailError = false;
    this.showConfirmPasswordError = false;
    let request: any;
    if (this.code) {
      request = this.resetForm.value;
      if (request.password.length == 0) {
        this.showPasswordError = true;
        return;
      }
      if (request.confirmPassword.length == 0) {
        // this.utils.showNotification('Please enter passwords');
        if (request.confirmPassword.length == 0) {
          this.showConfirmPasswordError = true;
        }
        return;
      }
      if (request.password !== request.confirmPassword) {
        this.utils.showNotification('New password and confirm password must be same');
        return;
      }
      if ((request.password.length > 0 && request.password.length < 6) || (request.confirmPassword.length > 0 && request.confirmPassword.length < 6)) {
        this.utils.showNotification('All password must be at least 6 characters');
        return;
      }
      request = {
        code: this.code,
        newPassword: request.password,
        confirmPassword: request.confirmPassword
      };
    } else {
      request = this.resetForm.value;
      if (request.email.length == 0) {
        // this.utils.showNotification('Please enter your email');
        this.showEmailError = true;
        return;
      }
      if (!this.utils.isValidEmail(request.email)) {
        this.showEmailError = true;
        return;
      }
      request = { email: request.email };
    }
    this.authService.forgotPassword(request).subscribe(data => {
      if (this.code) {
        this.utilService.notifySuccess('Password has been reset successfully.');
      }
      else {
        this.utilService.notifySuccess(data.message);
      }
      this.navigateToLogin();
    }, error => {
      this.utilService.notifyError(error);
    });
  }
}
